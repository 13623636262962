<template>
 <v-container
    id="fulfill"
    fluid
    tag="section"
  >
<PrintServerAlert />
<!-- <v-alert
  type="info"
  outlined
  round
  v-if="company.data_sources.filter(ds => ds.location_id && ds.uses_shopify_labels && !ds.approved_shopify_beta).length"
  small>
  One or more stores have applied for Shopify Labels Beta but the request is currently in review. You won't be able to fulfill those orders until the request is approved.
</v-alert> -->
<v-dialog
  v-model="dialog"
  width="500"
    >
    <v-card>
        <v-row  class="grey lighten-2">
          <v-col class="grow">
            <v-card-title class="text-h5 ">
              Skip parcel
            </v-card-title>
          </v-col>
          <v-col class="shrink">
            <v-btn
              icon
              @click="dialog = false"
              ><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>

        <v-card-text class="mt-8">
          Are you sure you wish to skip this parcel? The items will remain unfulfilled, and the order will be blocked. You can manually remove Order Blocks in Logs.
        </v-card-text>
        <v-card-actions class="flex-column">
            <v-btn
              color="primary"
              @click="dropParcel()"
              block
            >
              Skip parcel
            </v-btn>
            <v-btn
              text
              class="my-2"
              small
              @click="dialog = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
    </v-card>
</v-dialog>

<div class="text-center" v-if="loading || fetching_orders">
  <v-progress-circular
    indeterminate
    :size="50"
    color="primary"
    class="mt-12 mx-auto"
    ></v-progress-circular>
</div>

      <!-- {{ get_pick_order.map(item => item.name).toString() }} -->
<div v-else-if="!done_with_own_buckets" class="item-line">
    <ItemCard
      v-for="(item, i) in get_pick_order" :key="`pickCard-${i}`"
      :id="`pickCard-${i}`"
      :item="item"
      :active="i === get_current_item"
      :index="i"
      :request_count="request_counts.some(uuid => uuid === item.uuid)"
      @done="doneItem(i)"
      />
   
    <ParcelCard
      v-for="(order, i) in order_bucket"
      :id="`parcelCard-${i}`"
      :key="`parcelCard-${i}`"
      :order="order"
      :index="i"
      :active="i === get_current_parcel_card"
      :loading="i === loading_parcel"
      :show_manual_shipment="i === show_manual_shipment"
      :force_skip="i === force_skip"
      @done="doneParcel(i)"
      @drop="alertDropParcel(i)"
      @update="updateParcel"
      @scan_barcode="scan_barcode"
      @retry="handle_parcel_card(i)"
     />

     <ReturnCard
      v-for="(item, i) in get_return_items"
      :key="`returnCard-${i}`"
      :id="`returnCard-${i}`"
      :item="item"
      :active="i === returnIndex"
      @done="++returnIndex"
    />

    

</div>
  <!-- TODO: Lägg in ABANDONED BUCKETS HÄR -->
    <template v-if="done_with_own_buckets && !done_with_fulfillments">
      <AbandonedBucketsCard
        v-for="(bucket, index) in abandoned_buckets"
        :key="`abandoned-${index}`"
        :bucket="bucket"
        />
    </template>
  <v-alert
    v-if="done_with_fulfillments"
    type="success"
    rounded
    class="my-12 mx-3"
    >
    That's it! Nothing more to do here..
  </v-alert>
    
 </v-container>

</template>
<script>

import { mapGetters, mapState } from 'vuex'
// import { CurrencyFilter, DateFilter } from '@/utils/filters'
import ItemCard from '@/components/ItemCard'
// import ShippingCard from '@/components/ShippingCard'
import ReturnCard from '@/components/ReturnCard'
import ParcelCard from '@/components/ParcelCard'
import AbandonedBucketsCard from '@/components/AbandonedBucketsCard'
import PrintServerAlert from '@/components/PrintServerAlert'
import VueScrollTo from 'vue-scrollto'




export default {
  components: {
    ItemCard,
    // ShippingCard,
    ParcelCard,
    ReturnCard,
    AbandonedBucketsCard,
    PrintServerAlert,
  },
  computed: {
    ...mapGetters(
        'order', ['get_pick_order', 'get_current_item', 'get_current_parcel_card', 'get_return_items']
        ),
    ...mapGetters({
      get_printing_possible: 'warehouse/get_printing_possible',
      get_product: 'product/get_product',
      get_product_inventory_alarm: 'product/get_product_inventory_alarm',
    }),
    ...mapState({
      order_bucket: state => state.order.order_bucket,
      fetching_orders: state => state.order.fetching_orders,
      company: state => state.warehouse.company,
      abandoned_buckets: state => state.order.abandoned_buckets,
    }),
    done_with_own_buckets(){
      return !this.$store.state.order.did_check_abandoned && !this.$store.state.order.fetching_orders && !this.order_bucket.length
    },
    done_with_fulfillments(){
      return this.$store.state.order.did_check_abandoned && !this.$store.state.order.fetching_orders && !this.order_bucket.length
    }
  },
  watch: {
    done_with_own_buckets: async function(val){
      if(val){
        this.loading = true
        await this.$store.dispatch('order/get_abandoned_buckets')
        this.loading = false
      }
    },
    returnIndex: function(val) {
      if(this.loading || val === -1) return // OMG
      if(!this.get_return_items[val]) return this.refresh()
      return setTimeout(() => {
        VueScrollTo.scrollTo(`#returnCard-${val}`, 500,{
          offset: -75,
        });
      }, 100)
    },
    get_current_parcel_card: {
      handler: async function(val){
        console.log('parcel card', val);
        if(val === null) return
        if(val === -1) return this.returnIndex = 0
        setTimeout(() => {
          VueScrollTo.scrollTo(`#parcelCard-${val}`, 500,{
            offset: -75,
          });
        }, 100)
        await this.handle_parcel_card(val)
      },
      immediate: true
    },
    get_current_item: {
      handler: function (val) {
        if(val === -1) return
        setTimeout(() => {
            VueScrollTo.scrollTo(`#pickCard-${val}`, 500,{
              offset: -75,
            });
          }, 100)
      },
      immediate: true
    }
  },
  data() {
    return {
      loading: true,
      returnIndex: -1,
      show_manual_shipment: null,
      loading_parcel: null,
      force_skip: null,
      // prohibit_parcel_block: null,
      request_counts: [],
      dialog: false,
      parcel_to_drop: null,
    }
  },
  methods: {
    async handle_parcel_card(val){
      this.$store.commit('order/RESET_PARCEL_ERRORS', { index: val })
        if(!this.order_bucket[val].line_items.some(item => item.fulfilled_quantity)){
          // this.prohibit_parcel_block = val
          this.force_skip = val
          return this.$store.commit('order/SET_PARCEL_ERROR', {
            index: val, 
            message: 'No items on this parcel. Skip this parcel.'
          })
        } else if(this.order_bucket[val].line_items.some(item => item.removed_quantity && !item.override_parcel_block)
          && !this.company.data_sources.filter(ds => ds.id === this.order_bucket[val].sf_data_source_id)[0].allow_partial_fulfillment
          ) {
          // this.prohibit_parcel_block = val
          this.force_skip = val
          return this.$store.commit('order/SET_PARCEL_ERROR', {
            index: val, 
            message: 'Missing items on this parcel, and store does not allow partial fulfillments. Skip and return this parcel or allow partial fulfillments in Settings.'
          })
        } else if(!this.get_printing_possible) {
          return 
        } else if (!this.company.carriers.length 
        && !this.company.data_sources.filter(ds => ds.id === this.order_bucket[val].sf_data_source_id)[0].uses_shopify_labels) {
          this.force_skip = val
          return this.$store.commit('order/SET_PARCEL_ERROR', {
            index: val, 
            message: 'Shopify Labels is not setup on this store, and no external carriers integrated'
          })
        } 
        else if(
          !this.order_bucket[val].shipping_lines.length 
          && !this.order_bucket[val].sf_data.shipping_service
          // && !this.company.data_sources.filter(ds => ds.id === this.order_bucket[val].sf_data_source_id)[0].uses_shopify_labels
          ){
          this.show_manual_shipment = val
          return this.$store.commit('order/SET_PARCEL_ERROR', {
            index: val, 
            message: 'This order has no shipping method specified. Change in Shopify, or manually add a service below',
            type: 'warning'
          })
        } else if(
          !this.order_bucket[val].sf_data.shipping_service
          // && !this.company.data_sources.filter(ds => ds.id === this.order_bucket[val].sf_data_source_id)[0].uses_shopify_labels
          ){
          this.show_manual_shipment = val
          return this.$store.commit('order/SET_PARCEL_ERROR', {
            index: val, 
            message: `There is no service mapped out for ${this.order_bucket[val].shipping_lines[0].code}
             going to ${this.order_bucket[val].shipping_address.country}. Add one manually below, or set up in Settings to automatically map a service to ${this.order_bucket[val].shipping_lines[0].code} for future orders.`,
            type: 'warning',
          })
        } else if (
          this.company.data_sources.filter(ds => ds.id === this.order_bucket[val].sf_data_source_id)[0].uses_shopify_labels
          && !this.order_bucket[val].sf_data.shipping_service.selected_package
        ) {
          this.show_manual_shipment = val
          return 
        }
        this.show_manual_shipment = null
        this.loading_parcel = val
        if(!this.order_bucket[val].sf_data.labels || !this.order_bucket[val].sf_data.labels.length) await this.$store.dispatch('warehouse/request_parcel', val)
        this.loading_parcel = null
    },
    async scan_barcode({ index, barcode }){
      console.log('scanning', barcode);
      const item_index = this.order_bucket[index].line_items.findIndex(item => this.get_product(item.uuid).barcode === barcode)
      if(item_index === -1) return this.$store.commit('app/SET_SNACK_BAR', 'This item is not in this parcel')
      const line_item = this.order_bucket[index].line_items[item_index]
      line_item.is_scanned = true
      this.$store.commit('order/SET_LINE_ITEM', { index, item_index, line_item })
      await this.$store.dispatch('order/save_order_bucket')
    },
    doneItem(t){
      this.$store.dispatch('order/submit_item', t)
    },
    async doneParcel(i){
      this.loading_parcel = i
      if(!this.get_printing_possible){ 
          await this.$store.dispatch('order/check_parcel_fulfilled', i)
            .finally(() => this.loading_parcel = null)
      } else {
        await this.$store.dispatch('order/fulfill_parcel', i)
        .catch(e => {
          this.$store.commit('order/SET_PARCEL_ERROR', {
            index: i,
            message: `Fulfillment error - do not ship this parcel: ${e.name}: ${e.message}.`
          })
          this.force_skip = i
        })
        .finally(() => this.loading_parcel = null)
        }
    },
    alertDropParcel(i){
      this.parcel_to_drop = i
      this.dialog = true
    },
    async dropParcel(){
      this.dialog = false
      await this.$store.dispatch('order/block_order', this.parcel_to_drop)
      this.$store.dispatch('order/drop_parcel', this.parcel_to_drop)
      // if(i !== this.prohibit_parcel_block) 
    },
    async updateParcel({index, 
      // source, service, 
      ...shipping_service}){
      console.log('shipping_service', shipping_service);
      // if(source) this.order_bucket[index].shipping_lines[0].source = source
      // if(service) this.order_bucket[index].shipping_lines[0].code = service
      this.order_bucket[index].sf_data.shipping_service = { 
        ...this.order_bucket[index].sf_data.shipping_service,
        ...shipping_service
      }
      this.$store.commit('order/SET_ORDER_BUCKET', this.order_bucket)
      await this.$store.dispatch('order/save_order_bucket')
      this.loading_parcel = index
      this.show_manual_shipment = null
      this.$store.commit('order/RESET_PARCEL_ERRORS', { index })
      if(!this.order_bucket[index].sf_data.labels || !this.order_bucket[index].sf_data.labels.length) await this.$store.dispatch('warehouse/request_parcel', index)
      this.loading_parcel = null
    },
    async refresh(){
      console.log('refresh');
      this.loading = true
      this.returnIndex = -1
      this.force_skip = null
      // this.prohibit_parcel_block = null
      this.show_manual_shipment = null
      this.request_counts =  []
      await this.$store.dispatch('order/refresh_order_bucket')
      this.refresh_products()
      this.loading_parcel = null
      this.loading = false
    },
    async refresh_products(){
      await this.$store.dispatch('order/check_bucket_inventory')
      this.request_counts = this.get_pick_order.filter(item => {
        return item.last_pick && this.get_product_inventory_alarm(item.uuid)
      }).map(item =>  item.uuid)
    }

  },
  async mounted(){
    await this.$store.dispatch('order/get_orders', {company_uid: this.company.id, hard_refresh: false})
    this.refresh_products()
    this.loading = false
    if(this.get_current_parcel_card > 0){
      setTimeout(() => {
          VueScrollTo.scrollTo(`#parcelCard-${this.get_current_parcel_card}`, 500,{
            offset: -75,
          });
        }, 100)
    } else if (this.get_current_item > 0){
      setTimeout(() => {
            VueScrollTo.scrollTo(`#pickCard-${this.get_current_item}`, 500,{
              offset: -75,
            });
          }, 100)
    }
  },

  
}
</script>