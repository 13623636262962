<template>
 <v-container
    fluid
    tag="section"
    class="px-0 px-md-3 "
  >
  <v-card class="pt-2 text-center mx-sm-auto" :disabled="!active" max-width="600px">
    <!-- <v-alert
      v-if="disableFulfillment()"
      dense
      type="warning"
      class="ml-4 mr-4"
      >Return any picked items in this box to the shelves. Do not ship.
      </v-alert> -->
    <p class="text-h6 my-3" style="overflow-x: scroll; white-space: nowrap">
    {{ index | LetterFilter }}: {{ order.name }} - {{ order.shipping_address.name }}
    </p>
    <transition name="slide">
      <StreamBarcodeReader 
        v-if="request_barcode_scan" 
        v-show="show_text"
        class="my-3 mx-3"
        @loaded="show_text = true"
        @decode="on_scanned_barcode" 
        />
    </transition>
    <transition name="slide">
        <v-text-field 
          dense
          outlined
          label="Barcode"
          class="mx-3"
          v-model="barcode"
          v-if="request_barcode_scan && show_text"
          @input="manual_submit_barcode"
        />
    </transition>
    <v-alert
      v-if="order.note"
      outlined
      dense
      
      class="mx-4 text-caption"
      >
    {{ order.note }}
    </v-alert>
    <v-alert
      type="warning"
      v-if="order.shipping_address.country_code === 'BR'">
    Important: This order is going to Brazil. CPF number has to be added, or the order should be blocked.
    <ToolTip>
      CPF is necessary for Brazilian customs. Fulfillment Hero has no way of getting that number from the Shopify API. A store owner needs to manually type the number on the parcel, or you need a system where the store owner copies the number to the order note so you can manually type it out.
    </ToolTip>
    </v-alert>
    <v-alert
      type="warning"
      v-if="order.shipping_address.country_code === 'CN'">
      Important: This order is going to China. Resident id number needs to be added, or the order should be blocked.
      <ToolTip>
        Resident number is necessary for Chinese customs. Fulfillment Hero has no way of getting that number from the Shopify API. A store owner needs to manually type the number on the parcel, or you need a system where the store owner copies the number to the order note so you can manually type it out.
      </ToolTip>
    </v-alert>
    <ParcelErrorCard
      v-for="(error, i) in get_parcel_errors(index)"
      :key="`error-${i}`"
      :order="order"
      :index="index"
      :error="error"
      @retry="$emit('retry')"
      />
    <ShippingMapCard 
        v-if="(show_manual_shipment && !store.uses_shopify_labels)"
        :preset_country="order.shipping_address.country_code"
        @save="set_method"
        class="mx-4"
        />
    <ShopifyLabelsCard
      v-else-if="show_manual_shipment"
      :order="order"
      :store="store"
      @save="set_method"
      class="mx-4"
      />
    <div
      v-if="order.sf_data.labels && !loading"
      class="printBtn-row"
      >
      <v-menu 
        offset-y
        v-for="(label, i) in order.sf_data.labels"
        :key="`print-${i}`"
        >
      <template
        v-slot:activator="{ on, attrs }"
        >
        <v-btn
          small
          rounded
          outlined
          class="mx-2"
          v-bind="attrs"
          v-on="on"
        >
          {{ label.description }}
        </v-btn>
      </template>
      <v-list
        nav
        dense
        >
        <v-list-item
          @click="sendToPrint({pdf_ref: label.ref, description: label.description, media: label.media, })"
          v-if="company.printserver_id"
          :disabled="!get_printing_possible || !get_printserver_connected"
          >
          <!-- get_printing_possible && !$store.state.warehouse.company.print_server_connected -->
          <v-list-item-icon>
            <v-icon>mdi-printer-wireless</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Resend to printer</v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="openPrint(label.ref)"
          >
          <v-list-item-icon>
            <v-icon>mdi-open-in-new</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Open in new tab</v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="printWithDialog(label.ref)"
          >
          <v-list-item-icon>
            <v-icon>mdi-printer</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Print</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    </div>
    <v-container v-else-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-container>
    <v-container
      class="text-caption"
      >
      <template v-if="company.custom_scripts.some(i => i === 'avatar')">
        <!-- <v-chip
          v-if="Math.floor(Number(order.total_price) / 25) > 0 && order.sf_data_source_id === '98ca94188af'"
          class="my-4"
          color="secondary"
          outlined
          >
            {{ Math.floor(Number(order.total_price) / 25) }} cards
          </v-chip> -->
        <v-chip
          v-if="order.sf_data_source_id === '98ca94188af' && order.line_items.some(a => a.product_id === 4297505767517 || a.product_id === 4297503735901)"
          class="my-4"
          color="secondary"
          outlined
          >
            {{ new Date().getFullYear() }} Citizen sticker
          </v-chip>
      </template>
      <v-chip
        v-if="order.sf_data.shipping_service && ((order.sf_data.shipping_service.addons || []).some(a => a.id === 'SF_STAMPS') || order.sf_data.shipping_service.service_id === 'UX')"
        class="my-4"
        color="secondary"
        outlined
        >
          Add {{ amount_stamps }}x stamps
        </v-chip>
      <p 
        v-for="(item, item_i) in order.line_items.filter(item => item.fulfillable_quantity)" 
        :key="`item-${item_i}`" 
        :class="{'text-decoration-line-through': !item.fulfilled_quantity}"
        
        >
        <span v-if="item.removed_quantity && item.fulfilled_quantity" class="text-decoration-line-through">{{ item.fulfillable_quantity }}</span>
        {{ item.fulfilled_quantity }}x {{ item.name }}
          <v-icon
          v-if="get_product(item.uuid) && get_product(item.uuid).barcode && company.require_item_scanning && item.fulfilled_quantity"
          :color="item.is_scanned ? 'primary' : 'grey'"
          small
          >mdi-barcode-scan</v-icon>
      </p>

      <ParcelPhoto 
        v-if="!request_barcode_scan && this.active && company.require_fulfillment_photos"
        :order_index="index"
        />
      
    </v-container>
    <v-card-actions style="flex-direction: column">
      <v-btn 
        x-large 
        color="success" 
        class="mx-auto my-4"
        rounded 
        :loading="loading"
        @click="$emit('done')"
        :disabled="!enableFulfillment"
        >
        <v-icon>
          mdi-check
        </v-icon>
      </v-btn>
      <v-btn 
        color="warning" 
        class="mx-auto mb-4"
        rounded 
        small
        outlined
        v-if="(active && !loading) || force_skip"
        @click="$emit('drop')"
        >
        <!-- <v-icon>
          mdi-check
        </v-icon> -->
        {{ order.sf_data.shipping_service && order.sf_data.shipping_service.type === 'shopify' && order.sf_data.labels && order.sf_data.labels.length ? 'Void label and skip order' : 'Skip without fulfilling' }}
      </v-btn>
    </v-card-actions>
    
  </v-card>
    
 </v-container>

</template>

<script>

import { LetterFilter } from '@/utils/filters'
import { mapState, mapGetters } from 'vuex'
import ShippingMapCard from '@/components/ShippingMapCard'
import { StreamBarcodeReader } from "vue-barcode-reader";
import print from 'print-js'
import ParcelPhoto from '@/components/ParcelPhoto'
import ShopifyLabelsCard from '@/components/ShopifyLabelsCard'
import ParcelErrorCard from '@/components/ParcelErrorCard.vue';
import ToolTip from '@/components/ToolTip'
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  components: {
    ShippingMapCard,
    StreamBarcodeReader,
    ParcelPhoto,
    ShopifyLabelsCard,
    ParcelErrorCard,
    ToolTip,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true, 
      default: 0,
    },
    active: {
      type: Boolean, 
      required: true,
      default: false,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    show_manual_shipment: {
      type: Boolean, 
      required: true,
      default: false,
    },
    force_skip: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
    watch: {
    carrier: function(id){
      if(id && !this.shipping.carriers[id]) this.$store.dispatch('shipping/get_carrier_partners', id)
    }
  },
  computed: {
    ...mapState({
      // order_bucket: (state) => state.order.order_bucket,
      company: (state) => state.warehouse.company,
      shipping: state => state.shipping,
      photos: state => state.order.photos,
      // store: state => state.warehouse.company.data_sources.find(i => i.id === this.order.sf_data_source_id)
    }),
    ...mapGetters({
      get_parcel_errors: 'order/get_parcel_errors',
      get_available_methods: 'shipping/get_available_methods',
      get_printing_possible: 'warehouse/get_printing_possible',
      get_printserver_connected: 'warehouse/get_printserver_connected',
      get_product: 'product/get_product',
    }),
    store(){
      return this.company.data_sources.find(i => i.id === this.order.sf_data_source_id)
    },
    request_barcode_scan(){
      return this.company.require_item_scanning 
        && this.order.line_items.some(item => !item.is_scanned && this.get_product(item.uuid) && this.get_product(item.uuid).barcode && item.fulfilled_quantity && this.active)
    },
    enableFulfillment(){
      if(this.request_barcode_scan) return false
      if(this.force_skip) return false
      if(!this.get_printing_possible) return true
      if(this.company.require_fulfillment_photos && (!this.photos[this.index] || !this.photos[this.index].length)) return false
      return this.order.sf_data.labels && this.order.sf_data.labels.length
      //&& !this.disableFulfillment()
    },
    amount_stamps(){
      const breaks = {
        50: { SE: 1, ROW: 2},
        100: { SE: 2, ROW: 3},
        250: { SE: 3, ROW: 6},
        500: { SE: 4, ROW: 8},
        1000: { SE: 6, ROW: 11},
        2000: { SE: 7, ROW: 13},
      }
      for(const i in breaks){
        if(this.order.total_weight < parseInt(i)) return this.order.shipping_address.country_code === 'SE' ? breaks[i].SE : breaks[i].ROW
      }
    return '?'
    }
  },
  filters: {
    LetterFilter,
  },
  data() {
    return {
      carrier: null,
      service: null,
      loading_manual_shipment: false,
      barcode: '',
      show_text: false,
    }
  },
  methods: {
    manual_submit_barcode(){
      const barcode = this.barcode.replace(/ /gi, '')
      if(!this.order.line_items.find(item => this.get_product(item.uuid).barcode === barcode)) return
      this.$emit('scan_barcode', { barcode, index: this.index })
      this.barcode = ''
    },
    on_scanned_barcode(barcode){
      this.$emit('scan_barcode', { barcode, index: this.index })
    },
    set_method(req){
      this.loading_manual_shipment = true
      this.$emit('update', {...req, index: this.index})
      
    },
    async openPrint(file_ref){
      const url = await firebase.storage().ref().child(file_ref).getDownloadURL()
      window.open(url, 'new')
    },
    async printWithDialog(file_ref){
      const url = await firebase.storage().ref().child(file_ref).getDownloadURL()
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const blob = xhr.response;
        print({ printable: URL.createObjectURL(blob) })
      };
      xhr.open('GET', url);
      xhr.send();

      // new Blob([this.parcels[this.index][i].data], {type: 'application/pdf'})
      // const url = URL.createObjectURL(file)
      print({ printable: url })
    },
    sendToPrint({ pdf_ref, description, media }){ 
      this.$store.dispatch('warehouse/submit_print_job', { pdf_ref, description, media, carrier_id: this.order.sf_data.shipping_service.sf_carrier_account_id })
    },

  },
  mounted(){

  },

  
}
</script>
<style lang="scss" scoped>
  .printBtn-row {
    display: flex;
    justify-content: center;
  }
.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>